// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file rates/v1/rate.proto (package rates.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64, Timestamp} from "@bufbuild/protobuf";
import {Currency} from "../../common/v1/currency_pb.js";
import {Money} from "../../common/v1/money_pb.js";

/**
 * @generated from enum rates.v1.ChargeType
 */
export enum ChargeType {
  /**
   * @generated from enum value: CHARGE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHARGE_TYPE_ADJUSTMENT = 1;
   */
  ADJUSTMENT = 1,

  /**
   * @generated from enum value: CHARGE_TYPE_AIRHEART_SERVICE_FEE = 2;
   */
  AIRHEART_SERVICE_FEE = 2,

  /**
   * @generated from enum value: CHARGE_TYPE_AIRLINE_TAX = 3;
   */
  AIRLINE_TAX = 3,

  /**
   * @generated from enum value: CHARGE_TYPE_AIRPORT_TAX = 4;
   */
  AIRPORT_TAX = 4,

  /**
   * @generated from enum value: CHARGE_TYPE_BASE_RATE = 5;
   */
  BASE_RATE = 5,

  /**
   * @generated from enum value: CHARGE_TYPE_BOOKING_FEE = 6;
   */
  BOOKING_FEE = 6,

  /**
   * @generated from enum value: CHARGE_TYPE_CLEANING_FEE = 7;
   */
  CLEANING_FEE = 7,

  /**
   * @generated from enum value: CHARGE_TYPE_COMMISSION = 8;
   */
  COMMISSION = 8,

  /**
   * @generated from enum value: CHARGE_TYPE_COMPENSATION = 9;
   */
  COMPENSATION = 9,

  /**
   * @generated from enum value: CHARGE_TYPE_CREDIT_CARD_FEE = 10;
   */
  CREDIT_CARD_FEE = 10,

  /**
   * @generated from enum value: CHARGE_TYPE_DEPOSIT = 11;
   */
  DEPOSIT = 11,

  /**
   * @generated from enum value: CHARGE_TYPE_DISCOUNT = 12;
   */
  DISCOUNT = 12,

  /**
   * @generated from enum value: CHARGE_TYPE_EXTRA_PERSON_FEE = 13;
   */
  EXTRA_PERSON_FEE = 13,

  /**
   * @generated from enum value: CHARGE_TYPE_FACILITY_FEE = 14;
   */
  FACILITY_FEE = 14,

  /**
   * @generated from enum value: CHARGE_TYPE_MANDATORY_FEE = 15;
   */
  MANDATORY_FEE = 15,

  /**
   * @generated from enum value: CHARGE_TYPE_MANDATORY_TAX = 16;
   */
  MANDATORY_TAX = 16,

  /**
   * @generated from enum value: CHARGE_TYPE_FEE = 17;
   */
  FEE = 17,

  /**
   * @generated from enum value: CHARGE_TYPE_LOCAL_TAX = 18;
   */
  LOCAL_TAX = 18,

  /**
   * @generated from enum value: CHARGE_TYPE_PROPERTY_FEE = 19;
   */
  PROPERTY_FEE = 19,

  /**
   * @generated from enum value: CHARGE_TYPE_RECOVERY_CHARGES_AND_FEES = 20;
   */
  RECOVERY_CHARGES_AND_FEES = 20,

  /**
   * @generated from enum value: CHARGE_TYPE_RESORT_FEE = 21;
   */
  RESORT_FEE = 21,

  /**
   * @generated from enum value: CHARGE_TYPE_SALES_TAX = 22;
   */
  SALES_TAX = 22,

  /**
   * @generated from enum value: CHARGE_TYPE_SERVICE_FEE = 23;
   */
  SERVICE_FEE = 23,

  /**
   * @generated from enum value: CHARGE_TYPE_SURCHARGE = 24;
   */
  SURCHARGE = 24,

  /**
   * @generated from enum value: CHARGE_TYPE_TAX_AND_SERVICE_FEE = 25;
   */
  TAX_AND_SERVICE_FEE = 25,

  /**
   * @generated from enum value: CHARGE_TYPE_TAX = 26;
   */
  TAX = 26,

  /**
   * @generated from enum value: CHARGE_TYPE_TOURISM_TAX = 27;
   */
  TOURISM_TAX = 27,

  /**
   * @generated from enum value: CHARGE_TYPE_TRAVELER_SERVICE_FEE = 28;
   */
  TRAVELER_SERVICE_FEE = 28,

  /**
   * @generated from enum value: CHARGE_TYPE_UTILITY_FEE = 30;
   */
  UTILITY_FEE = 30,
}
// Retrieve enum metadata with: proto3.getEnumType(ChargeType)
proto3.util.setEnumType(ChargeType, "rates.v1.ChargeType", [
  { no: 0, name: "CHARGE_TYPE_UNSPECIFIED" },
  { no: 1, name: "CHARGE_TYPE_ADJUSTMENT" },
  { no: 2, name: "CHARGE_TYPE_AIRHEART_SERVICE_FEE" },
  { no: 3, name: "CHARGE_TYPE_AIRLINE_TAX" },
  { no: 4, name: "CHARGE_TYPE_AIRPORT_TAX" },
  { no: 5, name: "CHARGE_TYPE_BASE_RATE" },
  { no: 6, name: "CHARGE_TYPE_BOOKING_FEE" },
  { no: 7, name: "CHARGE_TYPE_CLEANING_FEE" },
  { no: 8, name: "CHARGE_TYPE_COMMISSION" },
  { no: 9, name: "CHARGE_TYPE_COMPENSATION" },
  { no: 10, name: "CHARGE_TYPE_CREDIT_CARD_FEE" },
  { no: 11, name: "CHARGE_TYPE_DEPOSIT" },
  { no: 12, name: "CHARGE_TYPE_DISCOUNT" },
  { no: 13, name: "CHARGE_TYPE_EXTRA_PERSON_FEE" },
  { no: 14, name: "CHARGE_TYPE_FACILITY_FEE" },
  { no: 15, name: "CHARGE_TYPE_MANDATORY_FEE" },
  { no: 16, name: "CHARGE_TYPE_MANDATORY_TAX" },
  { no: 17, name: "CHARGE_TYPE_FEE" },
  { no: 18, name: "CHARGE_TYPE_LOCAL_TAX" },
  { no: 19, name: "CHARGE_TYPE_PROPERTY_FEE" },
  { no: 20, name: "CHARGE_TYPE_RECOVERY_CHARGES_AND_FEES" },
  { no: 21, name: "CHARGE_TYPE_RESORT_FEE" },
  { no: 22, name: "CHARGE_TYPE_SALES_TAX" },
  { no: 23, name: "CHARGE_TYPE_SERVICE_FEE" },
  { no: 24, name: "CHARGE_TYPE_SURCHARGE" },
  { no: 25, name: "CHARGE_TYPE_TAX_AND_SERVICE_FEE" },
  { no: 26, name: "CHARGE_TYPE_TAX" },
  { no: 27, name: "CHARGE_TYPE_TOURISM_TAX" },
  { no: 28, name: "CHARGE_TYPE_TRAVELER_SERVICE_FEE" },
  { no: 30, name: "CHARGE_TYPE_UTILITY_FEE" },
]);

/**
 * @generated from enum rates.v1.SalesChannel
 */
export enum SalesChannel {
  /**
   * @generated from enum value: SALES_CHANNEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SALES_CHANNEL_WEBSITE = 1;
   */
  WEBSITE = 1,

  /**
   * @generated from enum value: SALES_CHANNEL_AGENT_TOOL = 2;
   */
  AGENT_TOOL = 2,

  /**
   * @generated from enum value: SALES_CHANNEL_MOBILE_APP = 3;
   */
  MOBILE_APP = 3,

  /**
   * @generated from enum value: SALES_CHANNEL_MOBILE_WEB = 4;
   */
  MOBILE_WEB = 4,

  /**
   * @generated from enum value: SALES_CHANNEL_META = 5;
   */
  META = 5,

  /**
   * @generated from enum value: SALES_CHANNEL_CACHE = 6;
   */
  CACHE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(SalesChannel)
proto3.util.setEnumType(SalesChannel, "rates.v1.SalesChannel", [
  { no: 0, name: "SALES_CHANNEL_UNSPECIFIED" },
  { no: 1, name: "SALES_CHANNEL_WEBSITE" },
  { no: 2, name: "SALES_CHANNEL_AGENT_TOOL" },
  { no: 3, name: "SALES_CHANNEL_MOBILE_APP" },
  { no: 4, name: "SALES_CHANNEL_MOBILE_WEB" },
  { no: 5, name: "SALES_CHANNEL_META" },
  { no: 6, name: "SALES_CHANNEL_CACHE" },
]);

/**
 * @generated from enum rates.v1.SalesEnvironment
 */
export enum SalesEnvironment {
  /**
   * @generated from enum value: SALES_ENVIRONMENT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SALES_ENVIRONMENT_HOTEL_PACKAGE = 1;
   */
  HOTEL_PACKAGE = 1,

  /**
   * @generated from enum value: SALES_ENVIRONMENT_LOYALTY = 2;
   */
  LOYALTY = 2,

  /**
   * @generated from enum value: SALES_ENVIRONMENT_HOTEL_ONLY = 3;
   */
  HOTEL_ONLY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SalesEnvironment)
proto3.util.setEnumType(SalesEnvironment, "rates.v1.SalesEnvironment", [
  { no: 0, name: "SALES_ENVIRONMENT_UNSPECIFIED" },
  { no: 1, name: "SALES_ENVIRONMENT_HOTEL_PACKAGE" },
  { no: 2, name: "SALES_ENVIRONMENT_LOYALTY" },
  { no: 3, name: "SALES_ENVIRONMENT_HOTEL_ONLY" },
]);

/**
 * @generated from enum rates.v1.RateOption
 */
export enum RateOption {
  /**
   * @generated from enum value: RATE_OPTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RATE_OPTION_MEMBER = 1;
   */
  MEMBER = 1,

  /**
   * @generated from enum value: RATE_OPTION_NET_RATES = 2;
   */
  NET_RATES = 2,

  /**
   * @generated from enum value: RATE_OPTION_CROSS_SELL = 3;
   */
  CROSS_SELL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RateOption)
proto3.util.setEnumType(RateOption, "rates.v1.RateOption", [
  { no: 0, name: "RATE_OPTION_UNSPECIFIED" },
  { no: 1, name: "RATE_OPTION_MEMBER" },
  { no: 2, name: "RATE_OPTION_NET_RATES" },
  { no: 3, name: "RATE_OPTION_CROSS_SELL" },
]);

/**
 * @generated from message rates.v1.Rate
 */
export class Rate extends Message<Rate> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: int64 expedia_property_id = 23;
   */
  expediaPropertyId = protoInt64.zero;

  /**
   * @generated from field: string room_id = 2;
   */
  roomId = "";

  /**
   * @generated from field: common.v1.Currency currency = 24;
   */
  currency = Currency.UNSPECIFIED;

  /**
   * @generated from field: int32 inclusive_price = 3;
   */
  inclusivePrice = 0;

  /**
   * @generated from field: int32 exclusive_price = 4;
   */
  exclusivePrice = 0;

  /**
   * @generated from field: int32 taxes_and_fees = 5;
   */
  taxesAndFees = 0;

  /**
   * @generated from field: int32 property_fees = 6;
   */
  propertyFees = 0;

  /**
   * @generated from field: int32 strikethrough = 7;
   */
  strikethrough = 0;

  /**
   * @generated from field: int32 gross_profit = 26;
   */
  grossProfit = 0;

  /**
   * @generated from field: int32 marketing_fee = 27;
   */
  marketingFee = 0;

  /**
   * @generated from field: int32 total_price = 28;
   */
  totalPrice = 0;

  /**
   * @generated from field: int32 available_rooms = 8;
   */
  availableRooms = 0;

  /**
   * @generated from field: int32 score = 29;
   */
  score = 0;

  /**
   * @generated from field: bool refundable = 11;
   */
  refundable = false;

  /**
   * @generated from field: rates.v1.Refundability refundability = 30;
   */
  refundability?: Refundability;

  /**
   * @generated from field: bool member_deal_available = 20;
   */
  memberDealAvailable = false;

  /**
   * @generated from field: bool is_member_deal = 21;
   */
  isMemberDeal = false;

  /**
   * @generated from field: bool is_optimized_distribution = 22;
   */
  isOptimizedDistribution = false;

  /**
   * @generated from field: map<string, rates.v1.Rate.OccupancyPricing> occupancy_pricing = 12 [deprecated = true];
   * @deprecated
   */
  occupancyPricing: { [key: string]: Rate_OccupancyPricing } = {};

  /**
   * @generated from field: string occupancy_code = 25;
   */
  occupancyCode = "";

  /**
   * @generated from field: repeated rates.v1.Rate.CancellationPenalty cancellation_penalties = 13;
   */
  cancellationPenalties: Rate_CancellationPenalty[] = [];

  /**
   * @generated from field: repeated string cancellation_policy = 31;
   */
  cancellationPolicy: string[] = [];

  /**
   * @generated from field: repeated rates.v1.Amenity amenities = 14;
   */
  amenities: Amenity[] = [];

  /**
   * @generated from field: repeated rates.v1.Rate.BedGroup bed_groups = 15;
   */
  bedGroups: Rate_BedGroup[] = [];

  /**
   * @generated from field: repeated rates.v1.Rate.Fee fees = 16 [deprecated = true];
   * @deprecated
   */
  fees: Rate_Fee[] = [];

  /**
   * @generated from field: int32 adult_count = 17;
   */
  adultCount = 0;

  /**
   * @generated from field: repeated int32 child_ages = 18;
   */
  childAges: number[] = [];

  /**
   * @generated from field: repeated rates.v1.PriceLineItem line_items = 19;
   */
  lineItems: PriceLineItem[] = [];

  constructor(data?: PartialMessage<Rate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "expedia_property_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "currency", kind: "enum", T: proto3.getEnumType(Currency) },
    { no: 3, name: "inclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "exclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "taxes_and_fees", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "property_fees", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "strikethrough", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 26, name: "gross_profit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 27, name: "marketing_fee", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 28, name: "total_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "available_rooms", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 29, name: "score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "refundable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "refundability", kind: "message", T: Refundability },
    { no: 20, name: "member_deal_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "is_member_deal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "is_optimized_distribution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "occupancy_pricing", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Rate_OccupancyPricing} },
    { no: 25, name: "occupancy_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "cancellation_penalties", kind: "message", T: Rate_CancellationPenalty, repeated: true },
    { no: 31, name: "cancellation_policy", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "amenities", kind: "message", T: Amenity, repeated: true },
    { no: 15, name: "bed_groups", kind: "message", T: Rate_BedGroup, repeated: true },
    { no: 16, name: "fees", kind: "message", T: Rate_Fee, repeated: true },
    { no: 17, name: "adult_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "child_ages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 19, name: "line_items", kind: "message", T: PriceLineItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate {
    return new Rate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate {
    return new Rate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate {
    return new Rate().fromJsonString(jsonString, options);
  }

  static equals(a: Rate | PlainMessage<Rate> | undefined, b: Rate | PlainMessage<Rate> | undefined): boolean {
    return proto3.util.equals(Rate, a, b);
  }
}

/**
 * @generated from message rates.v1.Rate.OccupancyPricing
 */
export class Rate_OccupancyPricing extends Message<Rate_OccupancyPricing> {
  /**
   * @generated from field: repeated rates.v1.Rate.Fee fees = 1;
   */
  fees: Rate_Fee[] = [];

  /**
   * @generated from field: rates.v1.Rate.Totals totals = 2;
   */
  totals?: Rate_Totals;

  constructor(data?: PartialMessage<Rate_OccupancyPricing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate.OccupancyPricing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fees", kind: "message", T: Rate_Fee, repeated: true },
    { no: 2, name: "totals", kind: "message", T: Rate_Totals },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate_OccupancyPricing {
    return new Rate_OccupancyPricing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate_OccupancyPricing {
    return new Rate_OccupancyPricing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate_OccupancyPricing {
    return new Rate_OccupancyPricing().fromJsonString(jsonString, options);
  }

  static equals(a: Rate_OccupancyPricing | PlainMessage<Rate_OccupancyPricing> | undefined, b: Rate_OccupancyPricing | PlainMessage<Rate_OccupancyPricing> | undefined): boolean {
    return proto3.util.equals(Rate_OccupancyPricing, a, b);
  }
}

/**
 * @generated from message rates.v1.Rate.Fee
 */
export class Rate_Fee extends Message<Rate_Fee> {
  /**
   * @generated from field: rates.v1.Rate.Fee.FeeType category = 1;
   */
  category = Rate_Fee_FeeType.UNSPECIFIED;

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  constructor(data?: PartialMessage<Rate_Fee>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate.Fee";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "enum", T: proto3.getEnumType(Rate_Fee_FeeType) },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate_Fee {
    return new Rate_Fee().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate_Fee {
    return new Rate_Fee().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate_Fee {
    return new Rate_Fee().fromJsonString(jsonString, options);
  }

  static equals(a: Rate_Fee | PlainMessage<Rate_Fee> | undefined, b: Rate_Fee | PlainMessage<Rate_Fee> | undefined): boolean {
    return proto3.util.equals(Rate_Fee, a, b);
  }
}

/**
 * @deprecated use ChargeType instead
 *
 * @generated from enum rates.v1.Rate.Fee.FeeType
 */
export enum Rate_Fee_FeeType {
  /**
   * @generated from enum value: FEE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FEE_TYPE_ADJUSTMENT = 1;
   */
  ADJUSTMENT = 1,

  /**
   * @generated from enum value: FEE_TYPE_AIRHEART_SERVICE_FEE = 2;
   */
  AIRHEART_SERVICE_FEE = 2,

  /**
   * @generated from enum value: FEE_TYPE_AIRLINE_TAX = 3;
   */
  AIRLINE_TAX = 3,

  /**
   * @generated from enum value: FEE_TYPE_AIRPORT_TAX = 4;
   */
  AIRPORT_TAX = 4,

  /**
   * @generated from enum value: FEE_TYPE_BASE_RATE = 5;
   */
  BASE_RATE = 5,

  /**
   * @generated from enum value: FEE_TYPE_BOOKING_FEE = 6;
   */
  BOOKING_FEE = 6,

  /**
   * @generated from enum value: FEE_TYPE_CLEANING_FEE = 7;
   */
  CLEANING_FEE = 7,

  /**
   * @generated from enum value: FEE_TYPE_COMMISSION = 8;
   */
  COMMISSION = 8,

  /**
   * @generated from enum value: FEE_TYPE_COMPENSATION = 9;
   */
  COMPENSATION = 9,

  /**
   * @generated from enum value: FEE_TYPE_CREDIT_CARD_FEE = 10;
   */
  CREDIT_CARD_FEE = 10,

  /**
   * @generated from enum value: FEE_TYPE_DEPOSIT = 11;
   */
  DEPOSIT = 11,

  /**
   * @generated from enum value: FEE_TYPE_DISCOUNT = 12;
   */
  DISCOUNT = 12,

  /**
   * @generated from enum value: FEE_TYPE_EXTRA_PERSON_FEE = 13;
   */
  EXTRA_PERSON_FEE = 13,

  /**
   * @generated from enum value: FEE_TYPE_FACILITY_FEE = 14;
   */
  FACILITY_FEE = 14,

  /**
   * @generated from enum value: FEE_TYPE_MANDATORY_FEE = 15;
   */
  MANDATORY_FEE = 15,

  /**
   * @generated from enum value: FEE_TYPE_MANDATORY_TAX = 16;
   */
  MANDATORY_TAX = 16,

  /**
   * @generated from enum value: FEE_TYPE_FEE = 17;
   */
  FEE = 17,

  /**
   * @generated from enum value: FEE_TYPE_LOCAL_TAX = 18;
   */
  LOCAL_TAX = 18,

  /**
   * @generated from enum value: FEE_TYPE_PROPERTY_FEE = 19;
   */
  PROPERTY_FEE = 19,

  /**
   * @generated from enum value: FEE_TYPE_RECOVERY_CHARGES_AND_FEES = 20;
   */
  RECOVERY_CHARGES_AND_FEES = 20,

  /**
   * @generated from enum value: FEE_TYPE_RESORT_FEE = 21;
   */
  RESORT_FEE = 21,

  /**
   * @generated from enum value: FEE_TYPE_SALES_TAX = 22;
   */
  SALES_TAX = 22,

  /**
   * @generated from enum value: FEE_TYPE_SERVICE_FEE = 23;
   */
  SERVICE_FEE = 23,

  /**
   * @generated from enum value: FEE_TYPE_SURCHARGE = 24;
   */
  SURCHARGE = 24,

  /**
   * @generated from enum value: FEE_TYPE_TAX_AND_SERVICE_FEE = 25;
   */
  TAX_AND_SERVICE_FEE = 25,

  /**
   * @generated from enum value: FEE_TYPE_TAX = 26;
   */
  TAX = 26,

  /**
   * @generated from enum value: FEE_TYPE_TOURISM_TAX = 27;
   */
  TOURISM_TAX = 27,

  /**
   * @generated from enum value: FEE_TYPE_TRAVELER_SERVICE_FEE = 28;
   */
  TRAVELER_SERVICE_FEE = 28,

  /**
   * @generated from enum value: FEE_TYPE_UTILITY_FEE = 30;
   */
  UTILITY_FEE = 30,
}
// Retrieve enum metadata with: proto3.getEnumType(Rate_Fee_FeeType)
proto3.util.setEnumType(Rate_Fee_FeeType, "rates.v1.Rate.Fee.FeeType", [
  { no: 0, name: "FEE_TYPE_UNSPECIFIED" },
  { no: 1, name: "FEE_TYPE_ADJUSTMENT" },
  { no: 2, name: "FEE_TYPE_AIRHEART_SERVICE_FEE" },
  { no: 3, name: "FEE_TYPE_AIRLINE_TAX" },
  { no: 4, name: "FEE_TYPE_AIRPORT_TAX" },
  { no: 5, name: "FEE_TYPE_BASE_RATE" },
  { no: 6, name: "FEE_TYPE_BOOKING_FEE" },
  { no: 7, name: "FEE_TYPE_CLEANING_FEE" },
  { no: 8, name: "FEE_TYPE_COMMISSION" },
  { no: 9, name: "FEE_TYPE_COMPENSATION" },
  { no: 10, name: "FEE_TYPE_CREDIT_CARD_FEE" },
  { no: 11, name: "FEE_TYPE_DEPOSIT" },
  { no: 12, name: "FEE_TYPE_DISCOUNT" },
  { no: 13, name: "FEE_TYPE_EXTRA_PERSON_FEE" },
  { no: 14, name: "FEE_TYPE_FACILITY_FEE" },
  { no: 15, name: "FEE_TYPE_MANDATORY_FEE" },
  { no: 16, name: "FEE_TYPE_MANDATORY_TAX" },
  { no: 17, name: "FEE_TYPE_FEE" },
  { no: 18, name: "FEE_TYPE_LOCAL_TAX" },
  { no: 19, name: "FEE_TYPE_PROPERTY_FEE" },
  { no: 20, name: "FEE_TYPE_RECOVERY_CHARGES_AND_FEES" },
  { no: 21, name: "FEE_TYPE_RESORT_FEE" },
  { no: 22, name: "FEE_TYPE_SALES_TAX" },
  { no: 23, name: "FEE_TYPE_SERVICE_FEE" },
  { no: 24, name: "FEE_TYPE_SURCHARGE" },
  { no: 25, name: "FEE_TYPE_TAX_AND_SERVICE_FEE" },
  { no: 26, name: "FEE_TYPE_TAX" },
  { no: 27, name: "FEE_TYPE_TOURISM_TAX" },
  { no: 28, name: "FEE_TYPE_TRAVELER_SERVICE_FEE" },
  { no: 30, name: "FEE_TYPE_UTILITY_FEE" },
]);

/**
 * @generated from message rates.v1.Rate.Totals
 */
export class Rate_Totals extends Message<Rate_Totals> {
  /**
   * @generated from field: int32 exclusive = 1;
   */
  exclusive = 0;

  /**
   * @generated from field: int32 inclusive = 2;
   */
  inclusive = 0;

  /**
   * @generated from field: int32 property_fees = 3;
   */
  propertyFees = 0;

  /**
   * @generated from field: int32 strikethrough = 4;
   */
  strikethrough = 0;

  constructor(data?: PartialMessage<Rate_Totals>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate.Totals";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exclusive", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "inclusive", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "property_fees", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "strikethrough", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate_Totals {
    return new Rate_Totals().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate_Totals {
    return new Rate_Totals().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate_Totals {
    return new Rate_Totals().fromJsonString(jsonString, options);
  }

  static equals(a: Rate_Totals | PlainMessage<Rate_Totals> | undefined, b: Rate_Totals | PlainMessage<Rate_Totals> | undefined): boolean {
    return proto3.util.equals(Rate_Totals, a, b);
  }
}

/**
 * @generated from message rates.v1.Rate.CancellationPenalty
 */
export class Rate_CancellationPenalty extends Message<Rate_CancellationPenalty> {
  /**
   * @generated from oneof rates.v1.Rate.CancellationPenalty.penalty_value
   */
  penaltyValue: {
    /**
     * @generated from field: common.v1.Money amount = 1;
     */
    value: Money;
    case: "amount";
  } | {
    /**
     * @generated from field: int32 nights = 2;
     */
    value: number;
    case: "nights";
  } | {
    /**
     * @generated from field: int32 percent = 3;
     */
    value: number;
    case: "percent";
  } | {
    /**
     * @generated from field: bool non_refundable_range = 6;
     */
    value: boolean;
    case: "nonRefundableRange";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp starts_at = 4;
   */
  startsAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp ends_at = 5;
   */
  endsAt?: Timestamp;

  constructor(data?: PartialMessage<Rate_CancellationPenalty>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate.CancellationPenalty";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "message", T: Money, oneof: "penalty_value" },
    { no: 2, name: "nights", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "penalty_value" },
    { no: 3, name: "percent", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "penalty_value" },
    { no: 6, name: "non_refundable_range", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "penalty_value" },
    { no: 4, name: "starts_at", kind: "message", T: Timestamp },
    { no: 5, name: "ends_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate_CancellationPenalty {
    return new Rate_CancellationPenalty().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate_CancellationPenalty {
    return new Rate_CancellationPenalty().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate_CancellationPenalty {
    return new Rate_CancellationPenalty().fromJsonString(jsonString, options);
  }

  static equals(a: Rate_CancellationPenalty | PlainMessage<Rate_CancellationPenalty> | undefined, b: Rate_CancellationPenalty | PlainMessage<Rate_CancellationPenalty> | undefined): boolean {
    return proto3.util.equals(Rate_CancellationPenalty, a, b);
  }
}

/**
 * @generated from message rates.v1.Rate.BedGroup
 */
export class Rate_BedGroup extends Message<Rate_BedGroup> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  constructor(data?: PartialMessage<Rate_BedGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Rate.BedGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Rate_BedGroup {
    return new Rate_BedGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Rate_BedGroup {
    return new Rate_BedGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Rate_BedGroup {
    return new Rate_BedGroup().fromJsonString(jsonString, options);
  }

  static equals(a: Rate_BedGroup | PlainMessage<Rate_BedGroup> | undefined, b: Rate_BedGroup | PlainMessage<Rate_BedGroup> | undefined): boolean {
    return proto3.util.equals(Rate_BedGroup, a, b);
  }
}

/**
 * @generated from message rates.v1.Amenity
 */
export class Amenity extends Message<Amenity> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional int32 value = 3;
   */
  value?: number;

  /**
   * @generated from field: optional string category = 4;
   */
  category?: string;

  constructor(data?: PartialMessage<Amenity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Amenity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Amenity {
    return new Amenity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Amenity {
    return new Amenity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Amenity {
    return new Amenity().fromJsonString(jsonString, options);
  }

  static equals(a: Amenity | PlainMessage<Amenity> | undefined, b: Amenity | PlainMessage<Amenity> | undefined): boolean {
    return proto3.util.equals(Amenity, a, b);
  }
}

/**
 * @generated from message rates.v1.PriceLineItem
 */
export class PriceLineItem extends Message<PriceLineItem> {
  /**
   * @generated from field: int32 amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: common.v1.Currency currency = 2;
   */
  currency = Currency.UNSPECIFIED;

  /**
   * @generated from field: bool is_included_in_total = 3;
   */
  isIncludedInTotal = false;

  /**
   * @generated from field: bool is_mandatory = 4;
   */
  isMandatory = false;

  /**
   * @generated from field: bool is_hidden = 5;
   */
  isHidden = false;

  /**
   * @generated from field: int32 position = 6;
   */
  position = 0;

  /**
   * @generated from field: rates.v1.ChargeType category = 7;
   */
  category = ChargeType.UNSPECIFIED;

  constructor(data?: PartialMessage<PriceLineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.PriceLineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "currency", kind: "enum", T: proto3.getEnumType(Currency) },
    { no: 3, name: "is_included_in_total", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_mandatory", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "category", kind: "enum", T: proto3.getEnumType(ChargeType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceLineItem {
    return new PriceLineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceLineItem {
    return new PriceLineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceLineItem {
    return new PriceLineItem().fromJsonString(jsonString, options);
  }

  static equals(a: PriceLineItem | PlainMessage<PriceLineItem> | undefined, b: PriceLineItem | PlainMessage<PriceLineItem> | undefined): boolean {
    return proto3.util.equals(PriceLineItem, a, b);
  }
}

/**
 * @generated from message rates.v1.Refundability
 */
export class Refundability extends Message<Refundability> {
  /**
   * @generated from field: rates.v1.Refundability.RefundableType type = 1;
   */
  type = Refundability_RefundableType.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp until = 2;
   */
  until?: Timestamp;

  constructor(data?: PartialMessage<Refundability>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.Refundability";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(Refundability_RefundableType) },
    { no: 2, name: "until", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Refundability {
    return new Refundability().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Refundability {
    return new Refundability().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Refundability {
    return new Refundability().fromJsonString(jsonString, options);
  }

  static equals(a: Refundability | PlainMessage<Refundability> | undefined, b: Refundability | PlainMessage<Refundability> | undefined): boolean {
    return proto3.util.equals(Refundability, a, b);
  }
}

/**
 * @generated from enum rates.v1.Refundability.RefundableType
 */
export enum Refundability_RefundableType {
  /**
   * @generated from enum value: REFUNDABLE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: REFUNDABLE_TYPE_NOT = 1;
   */
  NOT = 1,

  /**
   * @generated from enum value: REFUNDABLE_TYPE_FULL = 2;
   */
  FULL = 2,

  /**
   * @generated from enum value: REFUNDABLE_TYPE_PARTIAL = 3;
   */
  PARTIAL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Refundability_RefundableType)
proto3.util.setEnumType(Refundability_RefundableType, "rates.v1.Refundability.RefundableType", [
  { no: 0, name: "REFUNDABLE_TYPE_UNSPECIFIED" },
  { no: 1, name: "REFUNDABLE_TYPE_NOT" },
  { no: 2, name: "REFUNDABLE_TYPE_FULL" },
  { no: 3, name: "REFUNDABLE_TYPE_PARTIAL" },
]);

