// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file common/v1/money.proto (package common.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";
import {Currency} from "./currency_pb.js";

/**
 * Represents an amount of money with its currency type.
 *
 * @generated from message common.v1.Money
 */
export class Money extends Message<Money> {
  /**
   * Currency Code type
   *
   * @generated from field: common.v1.Currency currency_code = 1;
   */
  currencyCode = Currency.UNSPECIFIED;

  /**
   * Amount in cents
   *
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  constructor(data?: PartialMessage<Money>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "common.v1.Money";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currency_code", kind: "enum", T: proto3.getEnumType(Currency) },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Money {
    return new Money().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJsonString(jsonString, options);
  }

  static equals(a: Money | PlainMessage<Money> | undefined, b: Money | PlainMessage<Money> | undefined): boolean {
    return proto3.util.equals(Money, a, b);
  }
}

